<template>
  <v-card
    class="mx-auto pa-8"
    max-width="700"
    outlined
  >
    <v-card-title class="d-flex justify-center"> Войти в аккаунт </v-card-title>

    <v-form ref="loginForm">
      <v-text-field
        v-model="authorizationData.email"
        required
        label="Логин/Email"
        :error-messages="errors.email"
      />

      <v-text-field
        v-model="authorizationData.password"
        required
        label="Пароль"
        type="password"
        :error-messages="errors.password"
      />

      <v-card-text class="pa-0 mb-4 red--text">
        {{ singleError }}
      </v-card-text>

      <v-card-actions class="px-0">
        <v-btn
          color="success"
          class="mr-2 text-none"
          :loading="loading"
          @click="signIn"
        >
          Войти
        </v-btn>

        <v-btn
          text
          class="text-none"
          to="/register"
        >
          Регистрация
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import AuthService from '@/app/Services/AuthService'

export default {
  data: () => ({
    loading: false,
    authorizationData: {
      email: '',
      password: '',
    },
    errors: {
      email: [],
      password: [],
    },
  }),

  computed: {
    singleError() {
      return typeof this.errors === 'string' ? this.errors : ''
    },
  },

  methods: {
    async signIn() {
      this.loading = true

      const { status, message } = await AuthService.signIn(this.authorizationData)

      if (status) {
        this.$router.push('/')
      } else {
        this.errors = message
      }

      this.loading = false
    },
  },
}
</script>
